import {FeedBackFormProps} from "./FeedBackForm.props";
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Link,
  Snackbar,
  Stack,
  Typography
} from "@mui/material";
import InputField from "../InputField/InputField";
import {UserIcon} from "../../Icon/UserIcon";
import {PhoneIcon} from "../../Icon/PhoneIcon";
import {TelegramIcon} from "../../Icon/TelegramIcon";
import {useState} from "react";
// @ts-ignore
import styles from "./FeedBackForm.module.css";

const FeedBackForm = ({open, onClose, onSend}: FeedBackFormProps) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [telegram, setTelegram] = useState("");
  const [agree, setAgree] = useState(true);
  const [showSent, setShowSent] = useState(false);
  const [showValidateError, setShowValidateError] = useState(false);
  const [pending, setPending] = useState(false);

  const handleSubmit = () => {
    if ("" !== name && "" !== phone && agree) {
      setPending(true);
      fetch("https://back.cicap.ru/api/v1/front/feed-backs/landing", {
        method: "POST",
        body: JSON.stringify({name, phone, telegram, agree}),
        headers: {
          "Content-Type": "application/json",
          "X-Cicap-Landing": "Fin",
        }
      })
        .then(data => data.json())
        .then(data => {
          if (data.result) {
            setShowSent(true);
            setName("");
            setPhone("");
            setTelegram("");
          }
        })
        .finally(() => {
          setPending(false);
        });
    } else {
      setShowValidateError(true);
      setPending(false);
    }
  };

  return <>
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      sx={{
        ".MuiPaper-root": {
          borderRadius: "2rem",
          overflow: "hidden",
        },
      }}
    >
      <DialogContent sx={{px: 5, py: 4, position: "relative"}}>
        <button className={styles.CrossButton} onClick={onClose}>×</button>
        <Typography fontSize={{xs: "1.75rem", md: "3rem"}} fontWeight="600">Есть вопросы?</Typography>
        <Typography fontSize="1.1rem" sx={{color: "#6b6b6b"}}>Заполни форму и мы тебя проконсультируем.</Typography>

        {
          showSent ? <><Alert sx={{mt: 2}} onClose={() => setShowSent(false)} severity="success">Заявка успешно отправлена</Alert></> : <></>
        }

        <Stack direction="column" spacing={2} sx={{mt: 4}}>
          <InputField
            icon={UserIcon}
            iconColor="#525252"
            placeholder="Вашe имя"
            value={name}
            onChange={setName}
          />

          <InputField
            icon={PhoneIcon}
            iconColor="#525252"
            placeholder="Ваш телефон"
            value={phone}
            onChange={setPhone}
          />

          <InputField
            icon={TelegramIcon}
            iconColor="#36a7d0"
            placeholder="Ваш телеграм"
            value={telegram}
            onChange={setTelegram}
          />

          <FormControlLabel
            control={<Checkbox checked={agree} onChange={() => setAgree(!agree)} />}
            label={
              <Typography fontSize="small" color="#6d6d6d" fontWeight="600">
                Согласен на обработку персональных данных в соответствии c <Link target="_blank" href="https://cicap.ru/privacy-policy" sx={{textDecoration: "none"}}>политикой конфиденциальности</Link>.
              </Typography>
            }
          />

          <Button
            disabled={pending || !agree || "" === name || ("" === phone && "" === telegram)}
            variant="contained"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            disableElevation
            fullWidth
            sx={{
              textTransform: "none",
              fontSize: "1rem",
              py: 2.5,
            }}
            onClick={handleSubmit}
          >
            {pending ? "Отправляем..." : "Оставить заявку"}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>

    <Snackbar onClose={() => setShowSent(false)} open={showSent}>
      <Alert onClose={() => setShowSent(false)} severity="success">Заявка успешно отправлена</Alert>
    </Snackbar>

    <Snackbar onClose={() => setShowValidateError(false)} open={showValidateError} autoHideDuration={6000}>
      <Alert onClose={() => setShowValidateError(false)} severity="success">
        Необходимо заполнить имя, номер телефона и согласиться с обработкой персональных данных.
      </Alert>
    </Snackbar>
  </>
}

export default FeedBackForm;
