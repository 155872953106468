import {Box} from "@mui/material";
import Header from "../Component/Header/Header";
import Footer from "../Component/Footer/Footer";
import Hero from "../Component/Hero/Hero";
import ScrollLine from "../Component/ScrollLine/ScrollLine";
import Features from "../Block/Features/Features";
import Results from "../Block/Results/Results";
import Support from "../Block/Support/Support";
import Price from "../Block/Price/Price";

const FinLanding = () => {
  return <>
    <Header />

    <Hero />
    <Box sx={{transform: "rotate(1deg) translateY(-1.5rem)", marginBottom: "-1rem", position: "relative", zIndex: 100}}>
      <ScrollLine speed={60} text="Курс по трейдингу / " background="linear-gradient(to right, #b41c18, #dd1410)" color="#000000" />
    </Box>
    <Box sx={{zIndex: 10, position: "relative"}}>
      <ScrollLine speed={100} text="Получи технологию - следуй ей - зарабатывай / " background="linear-gradient(to right, #000000, #262626)" color="#ffffff" />
    </Box>
    <Features />
    <Results />
    <Support />
    <Price />

    <Footer />
  </>
}

export default FinLanding;
