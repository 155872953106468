import {Box, Button, Container, Grid, Typography} from "@mui/material";
// @ts-ignore
import styles from "./Price.module.css";
import FeedBackForm from "../../Component/FeedBackForm/FeedBackForm";
import {useEffect, useState} from "react";

const Price = () => {
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [refCode, setRefCode] = useState<string|null>(null);

  useEffect(() => {
    const queryString = new URLSearchParams(window.location.search);

    setRefCode(queryString.get('r'));
  }, []);

  return <>
    <Box className={styles.PriceBlock} sx={{pt: 6, pb: 0}}>
      <Typography fontSize={{xs: "1.75rem", md: "3.75rem"}} gutterBottom fontWeight="600" sx={{textAlign: "center"}}>Стоимость</Typography>
      <Container maxWidth="xl">
        <Grid container sx={{alignItems: "flex-end"}}>
          <Grid item xs={12} md={6}>
            <Box className={styles.DetailsBlock} id="price">
              <Typography fontSize={{xs: "1.15rem", md: "2.5rem"}} fontWeight="600">Курс по трейдингу: от новичка до PRO</Typography>
              <Box sx={{fontSize: {xs: "1rem", md: "1.4rem"}}}>
                <ul className={styles.list}>
                  <li>Поддержка кураторов на каждом этапе обучения</li>
                  <li>Уникальный софт, разработанный только для своих трейдеров и учеников</li>
                  <li>Возможность попасть на стажировку в дилинг компании Cicap</li>
                  <li>Закрытые чаты в Дискорд и Телеграм</li>
                  <li>Проверка домашних заданий</li>
                  <li>Закрытые стримы только для учеников</li>
                </ul>
              </Box>
              {
                (new Date().getTime() < new Date('2023-09-30').getTime()) ? (<>
                  <Typography fontSize="1.25rem" fontWeight="600" sx={{color: "#b41c18"}}>до 30 сентября</Typography>
                  <Typography fontSize={{xs: "2.5rem", md: "4rem"}} fontWeight="600">
                    <span style={{fontSize: "1.3em"}}>549$</span>
                    &nbsp;<s style={{color: "#656565"}}>690$</s>
                  </Typography>
                </>) : (<>
                  <Typography fontSize={{xs: "2.5rem", md: "4rem"}} fontWeight="600">
                    <span style={{fontSize: "1.3em"}}>690$</span>
                  </Typography></>)
              }
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{py: 2.5, textTransform: "none",}}
                    target="_blank"
                    href={`https://cicap.ru/checkout?product=fin-analyst${null !== refCode ? `&r=${refCode}` : ""}`}
                  >
                    Получить технологию
                  </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    target="_blank"
                    href={`https://cicap.ru/checkout?product=fin-analyst${null !== refCode ? `&r=${refCode}` : ""}`}
                    sx={{
                      py: 2.5,
                      background: "#ffffff",
                      color: "#000000",
                      textTransform: "none",
                      position: "relative",
                      zIndex: 100,
                      "&:hover": {
                        background: "#eeeeee",
                        color: "#000000",
                      },
                    }}
                  >
                    Рассрочка
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={5} sx={{userSelect: "none"}}>
            <Box sx={{position: "relative", transform: {md: "translateX(-2rem)", lg: "translateX(-7rem)"}}}>
              <Typography
                sx={{
                  position: {md: "relative", lg: "absolute"},
                  top: "23%",
                  right: "-15%",
                  fontStyle: "italic",
                  mb: 3,
                  textAlign: {xs: "center", lg: "left"},
                  "& span": {
                    color: "#6d6d6d",
                    display: "block",
                  }
                }}
              >
                <span>Спикер</span> Александр Третьяков
              </Typography>
              <img
                style={{
                  width: "100%",
                  display: "block",
                  maxHeight: "100vh",
                  objectFit: "contain",
                  position: "relative",
                  zIndex: 1000,
                }}
                src="/images/alex.png"
                alt="Спикер - Александр Третьяков"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
}

export default Price;
