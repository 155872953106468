import {Box, Container, Stack, Typography} from "@mui/material";
import FeatureItem from "../../Component/FeatureItem/FeatureItem";
// @ts-ignore
import styles from "./Features.module.css";

const Features = () => {
  return <>
    <Stack sx={{textAlign: "center", py: 6}}>
      <Container maxWidth="xl" sx={{position: "relative", zIndex: 10}}>
        <Typography fontSize={{xs: "1.75rem", md: "3.75rem"}} lineHeight={1.15} fontWeight="600" sx={{textAlign: "center"}} gutterBottom>
          Что тебя ждет<br /> при изучении технологии?
        </Typography>

        <Stack direction={{xs: "column", md: "row"}} justifyContent="center" alignItems={{xs: "center", md: "stretch"}} spacing={{xs: 1, md: 5}}>
          <Stack direction="column" spacing={{xs: 1, md: 12}} alignItems={{xs: "flex-start", md: "flex-end"}} justifyContent="center" sx={{order: {xs: 2, md: 1}}}>
            <FeatureItem
              index="01"
              title="01 блок"
              text="Вводные инструменты и понятия необходимые для применения технологии"
              align="right"
            />
            <Box sx={{transform: {xs: "none", md: "translateX(-4.5rem)"}}}>
              <FeatureItem
                index="02"
                title="02 блок"
                text="Подробное объяснение по использованию вводных инструментов"
                align="right"
              />
            </Box>
            <FeatureItem
              index="03"
              title="03 блок"
              text="Демонстрация основных инструментов для применения технологии"
              align="right"
            />
          </Stack>
          <Box sx={{order: {xs: 1, md: 2}}}>
            <div className={styles.FeaturesImageWrapper}>
              <img src="/images/phone.png" alt="Телефон" />
            </div>
          </Box>
          <Stack direction="column" spacing={{xs: 1, md: 12}} alignItems="flex-start" justifyContent="center" sx={{order: {xs: 3, md: 3}}}>
            <FeatureItem
              index="04"
              title="04 блок"
              text="Инструменты, позволяющие ВСЕГДА работать данной технологии, без сбоев"
              align="left"
            />
            <Box sx={{transform: {xs: "none", md: "translateX(4.5rem)"}, marginLeft: 0}}>
              <FeatureItem
                index="05"
                title="05 блок"
                text="Работа с психологией, дополнительная технология «золотое правило трех»"
                align="left"
              />
            </Box>
            <FeatureItem
              index="06"
              title="06 блок"
              text="Рынок - это толпа. Подробное изучение поведения толпы в любых ситуациях"
              align="left"
            />
          </Stack>
        </Stack>
      </Container>
    </Stack>
  </>
}

export default Features;
