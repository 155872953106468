import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Link,
  Snackbar,
  Stack,
  Typography
} from "@mui/material";
import {UserIcon} from "../../Icon/UserIcon";
import {TelegramIcon} from "../../Icon/TelegramIcon";
import {PhoneIcon} from "../../Icon/PhoneIcon";
import InputField from "../../Component/InputField/InputField";
import {useState} from "react";
// @ts-ignore
import styles from "./Results.module.css";

const Results = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [telegram, setTelegram] = useState("");
  const [agree, setAgree] = useState(true);
  const [showSent, setShowSent] = useState(false);
  const [showValidateError, setShowValidateError] = useState(false);
  const [pending, setPending] = useState(false);

  const handleSubmit = () => {
    if ("" !== name && "" !== phone && agree) {
      setPending(true);
      fetch("https://back.cicap.ru/api/v1/front/feed-backs/landing", {
        method: "POST",
        body: JSON.stringify({name, phone, telegram, agree}),
        headers: {
          "Content-Type": "application/json",
          "X-Cicap-Landing": "Fin",
        }
      })
        .then(data => data.json())
        .then(data => {
          if (data.result) {
            setShowSent(true);
            setName("");
            setPhone("");
            setTelegram("");
          }
        })
        .finally(() => {
          setPending(false);
        });
    } else {
      setShowValidateError(true);
      setPending(false);
    }
  };

  return <>
    <Box className={styles.ResultsBlock} sx={{py: 6}}>
      <Typography gutterBottom fontSize={{xs: "1.75rem", md: "3.75rem"}} lineHeight={1.15} fontWeight="600" sx={{textAlign: "center"}}>
        Результаты применения технологии
      </Typography>
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <img style={{display: "block", width: "100%"}} src="/images/result/1.png" alt="1" />
          </Grid>
          <Grid item xs={12} md={3}>
            <img style={{display: "block", width: "100%"}} src="/images/result/2.png" alt="2" />
          </Grid>
          <Grid item xs={12} md={3}>
            <img style={{display: "block", width: "100%"}} src="/images/result/3.png" alt="3" />
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack direction="column" spacing={3}>
              <img style={{display: "block", width: "100%"}} src="/images/result/4.png" alt="4" />
              <img style={{display: "block", width: "100%"}} src="/images/result/5.png" alt="5" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <img style={{display: "block", width: "100%"}} src="/images/result/6.png" alt="6" />
          </Grid>
          <Grid item xs={12} md={4}>
            <img style={{display: "block", width: "100%"}} src="/images/result/7.png" alt="7" />
          </Grid>
          <Grid item xs={12} md={4}>
            <img style={{display: "block", width: "100%"}} src="/images/result/8.png" alt="8" />
          </Grid>
        </Grid>
      </Container>
    </Box>
    <Box className={styles.FeedbackBlock} sx={{py: 6}}>
      <Typography fontSize={{xs: "1.75rem", md: "3.75rem"}} lineHeight={1.15} fontWeight="600" sx={{textAlign: "center"}}>Есть вопрос?</Typography>
      <Typography sx={{color: "#6b6b6b"}}>Заполни форму, мы проконсультируем по всем вопросам.</Typography>
      <Container maxWidth="xl" sx={{pt: 4}}>
        {
          showSent ? <><Alert sx={{mb: 2}} onClose={() => setShowSent(false)} severity="success">Заявка успешно отправлена</Alert></> : <></>
        }
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <InputField
              icon={UserIcon}
              iconColor="#525252"
              placeholder="Вашe имя"
              value={name}
              onChange={setName}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputField
              icon={PhoneIcon}
              iconColor="#525252"
              placeholder="Ваш телефон"
              value={phone}
              onChange={setPhone}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputField
              icon={TelegramIcon}
              iconColor="#36a7d0"
              placeholder="Ваш телеграм"
              value={telegram}
              onChange={setTelegram}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              disabled={pending}
              variant="contained"
              disableRipple
              disableFocusRipple
              disableTouchRipple
              disableElevation
              fullWidth
              onClick={handleSubmit}
              sx={{
                textTransform: "none",
                fontSize: "1rem",
                py: 2.5,
              }}
            >
              {pending ? "Отправляем..." : "Узнать подробнее"}
            </Button>
          </Grid>
        </Grid>
        <FormControlLabel
          sx={{mt: 2}}
          control={<Checkbox size="small" checked={agree} onChange={() => setAgree(!agree)} />}
          label={
            <Typography fontSize="small" color="#6d6d6d" fontWeight="600">
              Согласен на обработку персональных данных в соответствии c <Link target="_blank" href="https://cicap.ru/privacy-policy" sx={{textDecoration: "none"}}>политикой конфиденциальности</Link>.
            </Typography>
          }
        />
      </Container>
    </Box>

    <Snackbar onClose={() => setShowSent(false)} open={showSent}>
      <Alert onClose={() => setShowSent(false)} severity="success">Заявка успешно отправлена</Alert>
    </Snackbar>

    <Snackbar onClose={() => setShowValidateError(false)} open={showValidateError} autoHideDuration={6000}>
      <Alert onClose={() => setShowValidateError(false)} severity="error">
        Необходимо заполнить имя, номер телефона и согласиться с обработкой персональных данных.
      </Alert>
    </Snackbar>
  </>
}

export default Results;
