import {SvgIcon} from "@mui/material";

export const CicapLogoIcon = (props: any) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="4962.667" height="1425.333" viewBox="0 0 3722 1069" {...props}>
    <path d="M5013 10679c-604-38-1210-183-1766-420-833-357-1559-919-2132-1654-114-145-314-447-409-615C141 6990-88 5854 46 4710c72-619 252-1213 540-1780 270-533 591-970 1030-1400 205-201 315-297 526-455C3761-147 5951-340 7765 580c456 231 910 551 1244 876l106 103-281 282-282 282-128-116c-351-317-678-540-1079-737-390-192-771-317-1195-394-506-92-1088-92-1600 0-1087 196-2058 778-2758 1653-111 138-316 442-402 596-250 447-431 955-514 1445-75 442-86 918-30 1355 252 1974 1787 3570 3749 3899 279 47 438 60 755 60 497 0 918-64 1380-210 620-195 1251-568 1733-1024l88-83 280 279c153 153 279 284 279 290 0 12-210 207-345 319-1038 867-2398 1311-3752 1224z" fill="#fff" transform="matrix(.1 0 0 -.1 0 1069)" />
    <path d="M5080 9343c-19-1-87-8-150-14-946-89-1889-565-2539-1282-868-959-1220-2236-966-3503 127-628 408-1221 819-1729 126-155 446-472 606-599 747-594 1655-899 2605-873 121 4 279 13 350 22 870 103 1660 470 2289 1062l79 75-283 283-283 283-106-95c-134-120-292-240-446-338-136-88-433-236-575-289-745-273-1513-273-2260 0-162 60-464 215-625 322-203 135-343 249-510 417-458 457-763 1033-884 1665-67 353-71 774-11 1130 91 534 319 1040 664 1475 109 137 377 401 516 509 511 397 1087 623 1719 677 174 14 517 7 680-16 233-32 488-96 701-176 62-23 115-38 119-33 6 7 92 205 298 692l13 33-46 19c-94 41-364 129-499 163-243 62-450 97-690 117-110 9-502 11-585 3z" fill="#fff" transform="matrix(.1 0 0 -.1 0 1069)" />
    <path d="M7407 8653c-30-49-124-199-210-335l-156-248 77-52c153-102 369-276 446-357 17-17 35-31 40-31 6 0 137 126 291 281l280 280-75 71c-91 86-219 193-326 275-116 88-285 203-300 203-6 0-37-39-67-87z" fill="#fff" transform="matrix(.1 0 0 -.1 0 1069)" />
    <path d="M14765 8730c-824-63-1504-366-2074-924-293-288-482-541-657-881-198-385-314-807-355-1292-15-184-7-636 15-801 73-553 247-1027 542-1474 207-314 493-613 794-828 396-284 841-476 1320-569 209-41 332-53 615-58 368-7 581 12 874 79 667 151 1265 527 1715 1077l59 72-499 500-499 499-68-103c-241-365-615-628-1033-728-142-34-283-49-459-49-256 0-426 26-645 99-536 179-997 649-1188 1211-87 254-116 444-116 750 0 250 13 370 64 578 124 508 448 959 882 1228 461 286 1080 352 1613 173 147-50 339-147 465-237 157-111 375-344 460-492 7-12 121 97 506 481l496 496-75 89c-104 124-322 335-445 433-474 375-1022 596-1637 661-142 15-528 20-670 10zM18410 8255v-485h1360v970h-1360v-485z" fill="#fff" transform="matrix(.1 0 0 -.1 0 1069)" />
    <path d="M5115 8009c-720-67-1382-418-1835-974-532-654-724-1516-520-2335 47-188 106-344 200-535 145-294 296-501 531-730 433-421 960-671 1566-741 169-20 522-14 683 10 521 81 1009 310 1395 657l94 84-284 284-283 284-57-51c-272-249-623-412-1005-468-153-22-449-15-594 15-598 123-1073 487-1337 1026-289 589-241 1300 124 1845 473 707 1346 1007 2150 740 220-74 470-217 642-369l80-71 283 282 283 283-63 59c-403 376-936 624-1498 696-119 15-435 21-555 9z" fill="#fff" transform="matrix(.1 0 0 -.1 0 1069)" />
    <path d="M23035 7229c-371-31-683-115-994-270-273-135-484-291-711-525-210-217-342-404-470-666-248-507-320-1148-198-1768 92-471 335-940 673-1298 290-308 717-567 1136-692 288-85 526-114 884-107 271 5 369 16 585 63 410 90 845 317 1153 603l48 45-472 472-473 473-66-62c-202-191-477-300-790-314-278-12-545 63-759 214-144 102-323 299-407 448-229 411-244 968-36 1390 64 129 135 228 244 341 189 196 409 313 674 359 111 19 342 19 462 0 206-34 431-131 583-254 45-36 88-70 95-76 11-8 124 100 482 458l467 468-55 50c-227 204-532 389-813 493-357 132-820 190-1242 155zM28005 7229c-237-20-516-79-700-147-476-176-874-476-1180-887-262-352-408-710-482-1185-23-145-26-690-5-835 35-241 93-471 164-649 313-786 1006-1384 1808-1560 243-53 324-61 645-61 240 0 323 4 415 18 422 66 778 217 1116 473l123 93 10-37c6-20 26-102 46-182s40-162 46-182l10-38h1079v5060l-512-2-512-3-72-233c-40-129-75-236-77-239-3-3-41 24-84 60-344 284-752 460-1203 522-142 19-487 27-635 14zm637-1288c275-48 509-166 706-358 205-201 336-447 388-733 25-133 24-419 0-550-57-295-178-522-390-728-157-152-314-248-506-310-172-55-255-67-470-66-217 0-319 16-487 75-373 132-683 450-807 832-60 183-84 401-67 597 31 345 152 618 378 851 217 224 493 361 808 402 97 13 340 6 447-12zM34327 7230c-489-44-914-213-1290-512-48-39-91-68-94-64-4 3-57 104-118 224l-112 217-456 3-457 2V210h1370v1045c0 575 1 1045 3 1045s53-30 113-66c269-162 535-260 859-316 115-20 165-23 430-23 322 0 402 8 645 61 358 79 731 261 1030 501 47 37 137 120 201 183 511 510 769 1157 769 1931 0 787-267 1439-800 1952-402 388-891 620-1461 692-149 19-496 27-632 15zm433-1305c490-109 848-429 1000-895 54-165 65-245 64-470 0-175-3-221-23-310-58-271-177-487-371-680-193-192-399-301-680-361-152-33-448-33-600 0-281 60-493 176-687 373-187 191-301 409-360 688-25 121-25 467 0 593 107 530 459 909 969 1043 140 36 210 43 413 40 139-3 223-9 275-21zM18410 4570V2040h1360v5060h-1360V4570z" fill="#fff" transform="matrix(.1 0 0 -.1 0 1069)" />
    <path d="M8370 355c0-24 3-25 55-25h55V20h60v310h55c52 0 55 1 55 25v25h-280v-25zM8716 348c-3-18-15-89-26-158s-23-135-25-147c-5-20-1-23 24-23 35 0 34-3 57 143 9 59 21 104 25 100s30-57 58-118c28-60 55-110 61-110s33 50 61 110c27 61 54 114 58 118 5 5 16-45 26-110s21-122 25-126c4-5 19-7 32-5l25 3-28 170c-16 94-29 174-29 178 0 5-14 7-32 5-30-3-34-9-82-115-27-62-53-113-56-113-4 0-29 51-57 113-47 106-51 112-81 115-28 3-31 0-36-30z" fill="#fff" transform="matrix(.1 0 0 -.1 0 1069)" />
  </SvgIcon>
);
