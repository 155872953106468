import {FeatureItemProps} from "./FeatureItem.props";
import {Box, Stack, Typography} from "@mui/material";

const IndexDot = ({index}: {index: string}) => {
  return <Box
    sx={{
      background: "#b41c18",
      color: "#ffffff",
      width: "2.4rem",
      height: "2.4rem",
      borderRadius: "50%",
      flexShrink: 0,
      textAlign: "center",
      fontSize: "1.2rem",
      fontWeight: "600",
      lineHeight: "1.2rem",
      py: ".7rem",
    }}
  >
    {index}
  </Box>
}

const FeatureItem = ({index, title, text, align}: FeatureItemProps) => {
  return <Stack
    direction="row"
    alignItems="center"
    width={{xs: "100%", md: "calc(100% - 4rem)"}}
    spacing={2}
    sx={{
      marginRight: align === "right" ? 0 : "auto",
      marginLeft: align === "right" ? "auto" : 0,
    }}
  >
    <Box sx={{display: {xs: "block", md: "none"}}}><IndexDot index={index} /></Box>
    {
      align === "left" && <Box sx={{display: {xs: "none", md: "block", marginLeft: "0 !important"}}}><IndexDot index={index} /></Box>
    }
    <Box sx={{background: "#000000", color: "#ffffff", textAlign: {xs: "left", md: align}, borderRadius: .25, px: 2, py: 1.5}}>
      <Typography fontSize="1rem" fontWeight="800" color="#b41c18">{title}</Typography>
      <Typography fontSize="1rem" fontWeight="600">{text}</Typography>
    </Box>
    {
      align === "right" && <Box sx={{display: {xs: "none", md: "block"}}}><IndexDot index={index} /></Box>
    }
  </Stack>
}

export default FeatureItem;
