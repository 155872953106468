import {ScrollLineProps} from "./ScrollLine.props";
import {Box} from "@mui/material";
import Marquee from "react-fast-marquee";

const ScrollLine = ({text, background, color, speed}: ScrollLineProps) => {
  return <>
    <Box
      sx={{
        background,
        color,
        py: 1,
        pb: 0,
        fontSize: {xs: "1.75rem", md: "3.5rem"},
        fontWeight: "600",
        textTransform: "uppercase",
        width: "120vw",
        transform: "translateX(-10vw)",
      }}
    >
      <Marquee speed={speed}>{text.repeat(20)}</Marquee>
    </Box>
  </>
}

export default ScrollLine;
