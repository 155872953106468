import {Box, Icon, Stack, Typography} from "@mui/material";
import {IconWithTextProps} from "./IconWithText.props";

const IconWithText = ({icon, text}: IconWithTextProps) => {
  return <>
    <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{textAlign: "left"}} spacing={2}>
      <Box sx={{color: "#b41c18"}}>
        <Icon component={icon} sx={{fontSize: {xs: "2.5rem", md: "4rem"}}} />
      </Box>
      <Typography fontSize={{xs: ".9rem", md: "1.2rem"}}>{text}</Typography>
    </Stack>
  </>
}

export default IconWithText;
