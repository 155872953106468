import {SvgIcon} from "@mui/material";

export const ConsultationIcon = (props: any) => (
  <SvgIcon {...props} width="18" height="18" viewBox="0 0 66 66" fill="none">
    <path d="M58.9 65c-.3 0-.5-.1-.7-.3l-4.9-5H29.9c-1.1 0-2.2-.4-3-1.2s-1.2-1.8-1.2-3V37c0-.6.4-1 1-1s1 .4 1 1v18.6c0 .6.2 1.1.6 1.5s.9.6 1.5.6h23.9c.3 0 .5.1.7.3l3.5 3.6v-2.9c0-.5.5-1 1-1h1.9c.6 0 1.1-.2 1.5-.6s.6-1 .6-1.5V35.9c0-1.2-1-2.2-2.2-2.2H32.4c-.6 0-1-.4-1-1s.4-1 1-1h28.5c2.3 0 4.2 1.9 4.2 4.2v19.6c0 1.1-.4 2.2-1.2 3s-1.8 1.2-3 1.2H60l-.1 4.3c0 .4-.3.8-.6.9-.2 0-.3.1-.4.1z" />
    <path d="M3.3 38.8c-.3 0-.5-.1-.7-.3s-.3-.6-.3-.9l1.5-7.9C2 26.8 1 23.4 1 19.9 1 9.5 9.5 1 19.9 1s18.9 8.5 18.9 18.9-8.5 18.9-18.9 18.9c-2.6 0-5.2-.5-7.6-1.6l-8.8 1.6h-.2zm9.1-3.7c.1 0 .3 0 .4.1 2.3 1 4.6 1.6 7.1 1.6 9.3 0 16.9-7.6 16.9-16.9S29.2 3 19.9 3 3 10.6 3 19.9c0 3.2.9 6.4 2.7 9.1.1.2.2.5.1.7l-1.3 6.8 7.6-1.4zm1.2 22.6h-.4c-1.3-.2-2.2-1.4-2.2-2.7v-.6c0-2 1.3-3.8 3.1-4.3.9-.2 1.4-1.1 1.2-2-.2-.7-.8-1.2-1.5-1.2-.8 0-1.4.5-1.6.9-.5 1.1-1.8 1.8-3 1.5-.8-.2-1.5-.7-1.8-1.4-.4-.7-.4-1.6 0-2.3.8-1.7 2.3-3 4.1-3.6s3.8-.5 5.6.4c1.4.7 2.5 1.9 3.1 3.4.8 1.9.7 4-.1 5.8-.8 1.6-2 2.8-3.7 3.5V55c0 .8-.3 1.5-.9 2-.7.5-1.3.7-1.9.7zm-.1-2c.3 0 .5-.1.6-.1.2-.1.2-.3.2-.5 0-1 0-1.7.8-1.8 1.4-.4 2.4-1.4 3-2.6.6-1.3.6-2.8.1-4.2-.4-1-1.2-1.9-2.2-2.4-1.3-.7-2.7-.8-4-.3-1.3.4-2.4 1.4-2.9 2.6-.1.2-.1.4 0 .6.1.1.2.3.5.4s.6-.1.8-.4c.6-1.3 2-2.2 3.5-2.1 1.6.1 3 1.2 3.4 2.8.5 2-.7 3.9-2.6 4.4-.9.3-1.6 1.2-1.6 2.4v.6c-.2.2.1.5.4.6zm.1 9.3c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1-1.4 3.1-3.1 3.1zm0-4.3c-.6 0-1.1.5-1.1 1.1s.5 1.1 1.1 1.1 1.1-.5 1.1-1.1-.5-1.1-1.1-1.1zM55 23.1c-.6 0-1-.4-1-1v-1.2c0-2 .8-3.8 2.3-5.3 1.4-1.4 2.2-3.2 2.2-5.2s-.8-3.9-2.3-5.3-3.4-2.1-5.4-2c-3.8.2-6.8 3.3-6.9 7-.1 2.1.7 4.1 2.2 5.5s2.3 3.3 2.3 5.3v1.3c0 .6-.4 1-1 1s-1-.4-1-1v-1.3c0-1.4-.6-2.8-1.7-3.8-1.9-1.8-2.9-4.4-2.8-7 .2-4.8 4.1-8.7 8.9-8.9 2.6-.1 5 .8 6.9 2.6s2.9 4.2 2.9 6.7-1 4.9-2.8 6.6c-1.1 1.1-1.7 2.5-1.7 3.9v1.2c-.1.5-.6.9-1.1.9z" />
    <path d="M54.8 26.3h-7.2c-1.3 0-2.4-1.1-2.4-2.4v-.5c0-1.3 1.1-2.4 2.4-2.4h7.2c1.3 0 2.4 1.1 2.4 2.4v.5c-.1 1.3-1.1 2.4-2.4 2.4zm-7.2-3.2c-.2 0-.4.2-.4.4v.5c0 .2.2.4.4.4h7.2c.2 0 .4-.2.4-.4v-.5c0-.2-.2-.4-.4-.4z" />
    <path d="M51.1 29.6c-2.2 0-4-1.7-4-3.9v-.4c0-.6.4-1 1-1s1 .4 1 1v.4c0 1 .8 1.9 1.9 1.9s2-.8 2-1.9v-.4c0-.6.4-1 1-1s1 .4 1 1v.4c0 2.2-1.8 3.9-3.9 3.9zm.1-6.5c-.6 0-1-.4-1-1v-6.9c0-.3.1-.5.3-.7l2-2c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-1.7 1.7v6.5c0 .6-.5 1-1 1z" />
    <path d="M51.2 16.2c-.3 0-.5-.1-.7-.3l-2-2c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l2 2c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3zm-31.3 7c-2 0-3.6-1.5-3.8-3.4l-.7-8.2c-.1-1.3.3-2.5 1.2-3.5s2.1-1.5 3.4-1.5 2.4.4 3.2 1.4c.9.9 1.3 2.2 1.2 3.5l-.7 8.2c-.2 2-1.8 3.5-3.8 3.5zm0-14.7c-.7 0-1.4.3-1.9.8s-.7 1.2-.7 1.9l.7 8.2c.1.9.9 1.6 1.8 1.6s1.7-.7 1.8-1.6l.7-8.2c.1-.7-.2-1.4-.7-1.9-.3-.5-1-.8-1.7-.8zm0 24.8c-2 0-3.7-1.7-3.7-3.7s1.7-3.7 3.7-3.7 3.7 1.7 3.7 3.7-1.7 3.7-3.7 3.7zm0-5.4c-.9 0-1.7.8-1.7 1.7s.8 1.7 1.7 1.7 1.7-.8 1.7-1.7-.8-1.7-1.7-1.7zm25.4 10.9H32.2c-.6 0-1-.4-1-1s.4-1 1-1h13.1c.6 0 1 .4 1 1s-.4 1-1 1zm13.1 5.3H32.2c-.6 0-1-.4-1-1s.4-1 1-1h26.2c.6 0 1 .4 1 1s-.4 1-1 1zm0 5.3H32.2c-.6 0-1-.4-1-1s.4-1 1-1h26.2c.6 0 1 .4 1 1s-.4 1-1 1zm0 5.2H32.2c-.6 0-1-.4-1-1s.4-1 1-1h26.2c.6 0 1 .4 1 1s-.4 1-1 1z" />
  </SvgIcon>
);
