import {Box, Button, Container, Grid, Stack, Typography} from "@mui/material";
// @ts-ignore
import styles from "./Hero.module.css";
import FeedBackForm from "../FeedBackForm/FeedBackForm";
import {useState} from "react";
import jump from "jump.js";

const Hero = () => {
  const [formIsOpen, setFormIsOpen] = useState(false);

  return <Box className={styles.HeroBlock}>
    <Container maxWidth="xl" sx={{pt: "20vh", pb: "15vh"}}>
      <Grid container alignItems="center" spacing={10}>
        <Grid item xs={12} md={4.5}>
          <div className={styles.HeroImageWrapper}>
            <img src="/images/hero.png" alt="Александр" style={{display: "block", width: "100%"}} />
          </div>
        </Grid>
        <Grid item xs={12} md={7.5} sx={{textAlign: {sx: "center", md: "left"}}}>
          <Typography fontSize={{xs: "1.75rem", md: "3.75rem"}} lineHeight={1.15} fontWeight="600" gutterBottom sx={{textAlign: {sm: "center", md: "left"}}}>
            Технология,<br /> которая сделала из 500$ шестизначную сумму
          </Typography>
          <Typography fontSize={{xs: "1.2rem", md: "1.6rem"}} gutterBottom sx={{textAlign: {sm: "center", md: "left"}}}>
            Затрачивая при этом 20 минут в день
          </Typography>
          <Stack direction="row" spacing={2} sx={{mt: 5}} justifyContent={{sm: "center", md: "flex-start"}}>
            <Button
              variant="contained"
              sx={{textTransform: "none", height: "4.75rem", px: 6}}
              onClick={() => {
                jump('#price', {
                  duration: 2000,
                  offset: -80,
                });
              }}
            >
              Получить технологию
            </Button>
            <Button
              variant="contained"
              sx={{width: "4.75rem", height: "4.75rem"}}
              onClick={() => setFormIsOpen(true)}
            >
              ?
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Container>

    <FeedBackForm
      open={formIsOpen}
      onClose={() => setFormIsOpen(false)}
      onSend={() => setFormIsOpen(false)}
    />
  </Box>
}

export default Hero;
