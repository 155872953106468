import {Box, Container, Grid, Typography} from "@mui/material";
import IconWithText from "../../Component/IconWithText/IconWithText";
import {TelegramIcon} from "../../Icon/TelegramIcon";
import {BrainIcon} from "../../Icon/BrainIcon";
import {EmployeesIcon} from "../../Icon/EmployeesIcon";
import {LiveIcon} from "../../Icon/LiveIcon";
import {CertificateIcon} from "../../Icon/CertificateIcon";
import {DiscordIcon} from "../../Icon/DiscordIcon";
import {PadLockIcon} from "../../Icon/PadLockIcon";
import {HomeworkIcon} from "../../Icon/HomeworkIcon";
import {ConsultationIcon} from "../../Icon/ConsultationIcon";
import {DiscountIcon} from "../../Icon/DiscountIcon";

const Support = () => {
  return <>
    <Box sx={{pt: 6, textAlign: "center", background: "linear-gradient(40deg, #000000, #262626)", color: "#ffffff"}}>
      <Container maxWidth="xl">
        <Typography gutterBottom fontSize={{xs: "1.75rem", md: "3.75rem"}} fontWeight="600" sx={{textAlign: "center"}}>Поддержка в течении <span style={{color: "#b41c18"}}>года</span></Typography>
        <Typography gutterBottom sx={{maxWidth: "lg", fontSize: {xs: "1rem", md: "1.6rem"}, mx: "auto"}}>После получения технологии мы гарантируем поддержку от личного куратора, индивидуальный разбор домашних заданий и консультации в течении года.</Typography>
        <Typography gutterBottom sx={{maxWidth: "lg", fontSize: {xs: "1rem", md: "1.6rem"}, mx: "auto"}}>Так же ты получишь:</Typography>
        <Grid container spacing={{xs: 4, md: 7}} sx={{pt: 4}}>
          <Grid item xs={12} md={4}>
            <IconWithText icon={PadLockIcon} text="Доступ в закрытый канал трейдеров и инвесторов" />
          </Grid>
          <Grid item xs={12} md={4}>
            <IconWithText icon={BrainIcon} text="Доступ к софту, разработанному исключительно для нашей команды" />
          </Grid>
          <Grid item xs={12} md={4}>
            <IconWithText icon={EmployeesIcon} text="Возможность лучшим студентам попасть на стажировку в дилинг" />
          </Grid>
          <Grid item xs={12} md={4}>
            <IconWithText icon={DiscordIcon} text="Доступ в закрытый Discord со стримами топов компании" />
          </Grid>
          <Grid item xs={12} md={4}>
            <IconWithText icon={LiveIcon} text="Закрытый стрим с основателем компании Борисом Василькован" />
          </Grid>
          <Grid item xs={12} md={4}>
            <IconWithText icon={CertificateIcon} text="Сертификат об успешном прохождении курса" />
          </Grid>
          <Grid item xs={12} md={4}>
            <IconWithText icon={HomeworkIcon} text="Индивидуальный разбор домашних заданий" />
          </Grid>
          <Grid item xs={12} md={4}>
            <IconWithText icon={ConsultationIcon} text="Консультации с куратором в любое время по любым вопросам" />
          </Grid>
          <Grid item xs={12} md={4}>
            <IconWithText icon={DiscountIcon} text="Большой дисконт на другие продукты компании" />
          </Grid>
        </Grid>
      </Container>
      <img style={{width: "100%", display: "block"}} src="/images/team.png" alt="Команда" />
    </Box>
  </>
}

export default Support;
